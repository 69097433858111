.nav-web {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  background-color: #e1e1e1;
  padding: 10px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    li:hover {
      color: black;
      border: none;
      border-radius: 0;
      border-bottom: 2px solid #0069AA;
    }
  }

  img {
    max-height: 3rem;
    cursor: pointer;
  }
}

.btn {
  background-color: transparent;
  border-color: transparent;
  color: black;
  border: none;
}

.btn-show {
  background-color: transparent;
  border-color: transparent;
  color: black;
  border: none;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background-color: transparent;
  border-color: transparent;
  color: black;
  border: none;
}

.btn:hover {
  background-color: transparent;
  border-color: transparent;
  color: black;
  border: none;
}

.custom-dropdown .dropdown-toggle::after {
  content: none;
  padding: 0;
  background-color: transparent;
  width: 5rem;
}

.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
  color: black;
  border: none;
}

.dropdown-toggle:hover,
.dropdown-toggle:focus,
.dropdown-toggle:first-child:active,
.dropdown-toggle:first-child:active:focus-visible {
  color: black;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #0069AA;
  background-color: transparent;
  box-shadow: none;
}

.btn {
  color: black !important;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.btn:hover {
  color: black !important;
  // border: none !important;
  // border-radius: 0 !important;
  // border-bottom: 2px solid #2F2FBC !important;
  // background-color: transparent !important;
  box-shadow: none !important;
}

.list-options,
li {
  display: flex;
  gap: 0.5rem;
}

.title-option {
  width: 7rem;
}

.title-option:hover {
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #0069AA;
}

.col-options {
  display: flex;
  justify-content: center;
}

.dropdown-menu.show {
  display: flex !important;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 10px;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent !important;
  color: #0069AA !important;
}

.dropdown-header {
  font-size: 0.9rem !important;
}

.dropdown-item {
  font-size: 1rem !important;
}

.btn-check:checked+.btn:focus-visible,
:not(.btn-check)+.btn:active:focus-visible,
.btn:first-child:active:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible {
  box-shadow: none;
}

.btn-check:checked+.btn:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible,
.btn:first-child:active:focus-visible,
:not(.btn-check)+.btn:active:focus-visible {
  box-shadow: none;
}

.offcanvas-custom {
  width: 50% !important;
}

.offcanvas-body {
  display: flex !important;
  flex-direction: column !important;
}

.custom-dropdown.custom-z-index {
  z-index: 1000;
}

.dropstart .dropdown-toggle::before {
  display: none;
}

.list-options .custom-dropdown.show .dropdown-menu.show {
  display: flex !important;
  border: 1px solid #d1d1d1;
  border-radius: 0;
  box-shadow: none;
  padding: 10px;
  z-index: 2000 !important;
}

@media screen and (max-width: 768px) {
  .nav-web {
    width: 100%;
  }

  .list-options {
    flex-direction: column;
    gap: 1rem;
    font-size: 1.2rem;

    button {
      font-size: 1.2rem;
    }
  }

  .dropdown-menu.show {
    position: fixed !important;
    width: 50% !important;
    inset: 0px 20px auto auto !important;
  }

  .list-options .custom-dropdown.show .dropdown-menu.show {
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .dropdown-header {
    font-size: 1.1rem !important;
    width: 100%;
    padding: 5px 8px;
  }

  .dropdown-menu.show {
    width: 50% !important;
  }

  .dropdown-toggle {
    padding: 0;
  }

  .col-options {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      text-align: end;
    }
  }
}