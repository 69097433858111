.footer-web {
  position: static;
  width: 100%;
  background-color: #222222;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #FAFAFA;
  padding: 2rem 0;
}

.contact,
.redes {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  span {
    text-align: center;
  }
}

.social-icon {
  color: #FAFAFA;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  img {
    max-width: 15rem;
    cursor: pointer;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  li:hover {
    transform: scale(1.05);
  }
}

.redes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.logo,
.redes,
.contact {
  width: 20rem;
}

.footer-contact {
  display: flex;
}

.icons {
  display: flex;
  gap: 1rem;
  cursor: pointer;
  justify-content: center;

  svg {
    background-color: #0069AA;
    border-radius: 50%;
    padding: 10px;
    height: 40px;
    width: 40px;
  }

  svg:hover {
    transform: scale(1.1);
  }
}

.details {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  svg {
    background-color: #FAFAFA;
    border-radius: 50%;
    padding: 10px;
    height: 40px;
    width: 40px;
    color: #101010;
    cursor: pointer;
  }

  svg:hover {
    transform: scale(1.05);
  }
}

.location {
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: flex-start;

  p {
    margin: 0;
    padding: 0;
  }
}

.texts {
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    text-align: start;
  }
}

@media screen and (max-width: 768px) {
  .footer-web {
    flex-direction: column;
    width: 100%;
  }

  .footer-contact {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .logo,
  .redes,
  .contact {
    width: auto;
  }

}