.model-info {
  display: flex;
  text-align: center;
  width: 100%;
  height: 25rem;

  img {
    width: 60%;
    height: 100%;
    object-fit: cover;
  }
}

.text-container {
  background-color: rgba(214, 214, 214, 0.8);
  padding: 20px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  li {
    display: flex;
    text-align: start;
  }

  .text-highlight {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h6 {
    font-size: 1.2rem;
    margin-bottom: 5px;
    text-align: start;
    width: 7rem;
  }

  p {
    font-size: 14px;
    color: #3d3d3d;
    margin: 0;
  }
}

.static-carousel {
  .carousel-caption {
    left: 5%;
    right: unset;

    h1 {
      font-size: calc(2.5rem + 1.5vw);
      font-weight: bold;
    }
  }

  .carousel-inner {
    height: 35rem;
  }
}

.caracteristics {
  width: 100%;
  margin-bottom: 3rem;
}

.model-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 0.1rem;
}

.nav-link {
  color: black;
}

.nav-tabs .nav-link {
  border: none;
  width: 7rem;
}

.nav-link.active {
  color: #0069AA !important;
  background-color: transparent !important;
  border-bottom: 2px solid #0069AA !important;
  font-weight: 600;
}

.nav-link:hover {
  color: unset;
  border-bottom: 2px solid #0069AA !important;
}

.nav-link:focus-visible {
  box-shadow: none;
}

.tab-content .active {
  box-shadow: 0px 8px 10px 6px rgba(0, 0, 0, 0.1);
  display: flex;
}

@media screen and (max-width: 768px) {
  .model-info {
    flex-direction: column;
    height: unset;

    img {
      height: 15rem;
      width: 100%;
    }
  }

  .text-container {
    position: relative;
    width: 100%;
    height: 100%;
    transform: none;
    top: 0;
    right: 0;
    margin-bottom: 0.5rem;
    display: flex;
  }

  .caracteristics {
    width: 100%;
  }

  .tab-content {
    .tabpanel.active {
      display: flex;
      flex-direction: column;
    }

    .active {
      flex-direction: column;
    }

    .carousel.slide {
      width: 100%;
      height: 20rem;
    }

    .carousel-inner {
      height: 100%;
      width: 100%;
    }

    .info-tab {
      width: 100%;
      margin: 1rem 0 0 0;
    }

  }

  .caracteristics {
    width: 100%;
  }

  .model-tabs {
    li {
      font-size: calc(0.8rem + 0.9vw);
    }

    display: flex;
    justify-content: space-between;
  }

  .static-carousel .carousel-inner {
    width: 100%;
    height: 15rem;
  }

  .nav-tabs .nav-link {
    padding: 5px 10px;
  }

  .chars {
    display: flex !important;
    flex-direction: column !important;
    margin: 0.5rem;
  }

  .nav-tabs .nav-link {
    width: unset;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .model-info {
    img {
      width: 100%;
    }
  }

  .text-container {
    right: -3%;
    width: 22rem;
  }

  .caracteristics {
    width: 100%;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1150px) {
  .text-container {
    width: 26rem;
  }
}