.trayect {
  margin-top: 1rem;
}

.timeline {
  display: flex;
  flex-direction: column;
  margin-left: 20%;
  margin-bottom: 1.5rem;

  &__event {
    background: #fff;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    max-width: 80%;
    margin: 8px 0;
    border-radius: 8px;
    box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
      0 18px 36px -18px rgba(0, 0, 0, 0.3),
      0 -12px 36px -8px rgba(0, 0, 0, 0.025);

    &__title {
      font-size: 1rem;
      line-height: 1.4;
      text-transform: uppercase;
      text-align: start;
      font-weight: 600;
      color: black;
      letter-spacing: 1.2px;
    }

    &__content {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }

    &__date {
      color: whitesmoke;
      font-size: 1.2rem;
      font-weight: 600;
      white-space: nowrap;
    }

    &__icon {
      border-radius: 8px 0 0 8px;
      background: #c1121f;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 40%;
      font-size: 2rem;
      color: #c1121f;
      padding: 10px;

      i {
        position: absolute;
        top: 50%;
        left: -65px;
        font-size: 2.5rem;
        transform: translateY(-50%);

      }
    }

    &__description {
      flex-basis: 60%;
    }

    &:after {
      content: "";
      width: 2px;
      height: 45px;
      background: #c1121f;
      position: absolute;
      top: 30px;
      left: -57px;
      z-index: -1;
    }

    &:before {
      content: "";
      width: 2rem;
      height: 2rem;
      position: absolute;
      background: #c1121f;
      border-radius: 100%;
      left: -4.5rem;
      top: 50%;
      transform: translateY(-50%);
      border: 2px solid #c1121f;
    }

    &--type2 {
      &:before {
        background: #003049;
        border-color: #003049;
      }

      &:after {
        background: #003049;

      }

      .timeline__event__date {
        color: whitesmoke;
      }

      .timeline__event__icon {
        background: #003049;
        color: #0c3b5a;
      }

      .timeline__event__title {
        color: black;
      }
    }

    &--type3 {
      &:before {
        background: #3a5a40;
        border-color: #3a5a40;
      }

      &:after {
        background: #3a5a40;

      }

      .timeline__event__date {
        color: whitesmoke;
      }

      .timeline__event__icon {
        background: #3a5a40;
        color: #3a5a40;
      }

      .timeline__event__title {
        color: black;
      }
    }

    &:last-child {

      &:after {
        content: none;
      }
    }

    &--type4 {
      &:before {
        background: #343a40;
        border-color: #343a40;
      }

      &:after {
        background: #343a40;

      }

      .timeline__event__date {
        color: whitesmoke;
      }

      .timeline__event__icon {
        background: #343a40;
        color: whitesmoke;
      }

      .timeline__event__title {
        color: black;
      }
    }

    &:last-child {

      &:after {
        content: none;
      }
    }
  }
}

.description {
  font-size: 1.05rem;
  text-align: start;
  line-height: 2;
  margin: 2rem;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 786px) {
  .timeline__event {
    flex-direction: column;
    max-width: 80%;
  }

  .timeline__event__icon {
    border-radius: 4px 4px 0 0;
  }

  .timeline {
    &__event {
      &:after {
        height: 130px;
        top: 50px;
      }
    }
  }
}