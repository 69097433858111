.office-img {
  width: 100%;
  height: 15rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  img {
    max-height: 30%;
    object-fit: cover;
  }
}

.wrap-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  justify-content: center;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 2px 2px 8px 0px #495057;

  h6 {
    font-size: calc(1rem + 0.9vw);
    text-decoration: underline;
    text-underline-offset: 0.2em;
    margin-bottom: 1rem;
  }
}

.line-wrap {
  display: flex;
  width: 100%;
}

.info-details {
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 50%;
}

.maps-iframe {
  border-radius: 8px;
  height: 250px;
  width: 100%;
}

.description {
  margin: 0;
}

@media screen and (max-width: 768px) {
  .line-wrap {
    flex-direction: column;
    gap: 1rem;
  }

  .info-details {
    margin: 0;
    width: unset;
  }
}