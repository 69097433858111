.content-page {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.title{
  width: 100%;
  text-align: start;
  h2 {
    font-size: calc(0.9rem + 0.9vw);
    margin-left: 1rem;
    font-style: italic;
    font-weight: 200;
    margin-top: 0.5rem;
  }
}

.page-indicator {
  width: 100%;
  text-align: start;
  h5 {
    font-size: calc(0.9rem + 0.9vw);
    margin-left: 1rem;
    font-style: italic;
    font-weight: 200;
  }
}

.subtitle {
  text-align: start;
  margin: 1rem 2rem;

  h4 {
    font-size: calc(1.1rem + 0.9vw);
  }
}