.customers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem 4rem;
  gap: 2.5rem;
}

.client-card {
  max-width: calc(18%);

}

.card-text {
  margin: 0;
  text-align: start;
}

.client-img {
  max-width: 18rem;
}

@media screen and (max-width: 768px) {
  .client-card {
    max-width: 100%;
  }
}