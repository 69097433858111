.selected-index {
  border: none;
  border-radius: 0;
  border-right: 10px solid #0069AA !important;
  border-color: #0069AA !important;
  color: #0069AA;
  font-weight: bold;
}

.selected-index:hover {
  box-shadow: unset !important;
}

.models-cuadricula {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
}

.model-card {
  padding: 10px;
  text-align: center;
  cursor: pointer;

  p {
    margin: 0;
    padding: 0;
  }

  img {
    border-radius: 10px;
    max-width: 100%;
    height: 10rem;
    object-fit: cover;
  }

  img:hover {
    transform: scale(1.1);
  }
}

.indexes {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 8rem;
  margin-left: 3rem;
  gap: 0.5rem;
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border: 1px solid black;
    padding: 7px 10px;
    border-radius: 8px;
  }

  li:hover {
    box-shadow: inset 0px 0px 2px 1px #0069AA;
  }
}

.index-model {
  margin-left: 3rem;
}

.models {
  display: flex;
  flex-direction: column;
  margin: 2rem;
  min-height: 18rem;

  h4 {
    margin-bottom: 0.5rem;
  }
}

.models-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.models-render {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
}

@media screen and (max-width: 768px) {
  .models {
    margin: 1rem;
  }

  .index-model {
    margin: 0;
  }

  .models-cuadricula {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .model-card {
    img {
      height: 10rem;
      max-width: 100%;
    }
  }

  .models-render {
    flex-direction: column;
  }

  .indexes {
    flex-direction: row;
    gap: unset;
    justify-content: space-between;
    margin: 0rem auto;
    padding: 3px 10px;
    width: 12rem;
    border-radius: 8px;
  }

  .indexes li {
    border: unset;
    box-shadow: unset;
    border-radius: unset;
    width: 10rem;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .selected-index {
    border-bottom: 2px solid #0069AA !important;
    border-right: unset !important;
  }
}