.carousel-inner {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  height: 35rem;
}

.carousel-item {
  height: 100%;
}

.carousel-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.office-carousel {
  width: 50%;

  .carousel-inner {
    border-radius: 8px;
  }
}

.carousel-caption {
  background-color: rgb(0 0 0 / 50%) !important;
  padding: 5px !important;
}

@media screen and (max-width: 450px) {
  .carousel-inner {
    height: 15rem;
  }
}

@media screen and (max-width: 768px) {
  .office-carousel {
    width: 100%;
  }
}