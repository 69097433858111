.tab-model {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 1rem 1rem 1rem;
  gap: 2rem;
}

.infotab {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 0 0.5rem;
}

.list-group {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.list-group-item {
  font-size: 1.1rem;
  text-align: start;
  margin: 0;
  box-shadow: 1px 2px 3px #afafaf;
  height: 100%;
  display: flex;
  align-items: center;
}

.infotab li:not(:last-child) {
  margin-bottom: 0.5rem;
}

.chars {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-bottom: 1rem;
}

.carousel-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 15rem;
  box-shadow: 0px 1px 3px 0px #afafaf;
  border-radius: 4px;

  img {
    max-width: 25rem;
    min-width: 25rem;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.infotest-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  text-align: start;
  overflow: hidden;

  p {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
    color: #5e5e5e;
    font-style: italic;
  }
}

@media screen and (max-width: 768px) {
  .carousel-info img {
    max-width: 70%;
    min-width: 70%;
    min-height: 10rem;
  }

  .tab-model {
    margin: 0;
  }

  .infotab {
    display: flex !important;
    flex-direction: column !important;
    padding-bottom: 1rem;
  }

  .infotest-carousel p {
    font-size: calc(0.85rem + 0.2vw);
  }

  .carousel-info {
    height: unset;
  }
}