@import './styles/variables';
@import './styles/mixins';
@import "~bootstrap/scss/bootstrap";

.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

html, body {
  margin: 0;
}

body {
  background-color: $background-color;
  font-family: $font-family;
  color: $text-color;
  // padding: $padding;
}

.swal2-container.swal2-center > .swal2-popup {
  button {
    background-color: #0D6EFD;
  }
}